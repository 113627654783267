import React from 'react';
import {Loader} from 'smart-react';
/**
 * If user have no permission it'll redirect to this page
 * @returns {JSX.Element} no permission tag
 */
const NoPermission = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <div className="layout-facility">
        <div className="layout-facility-wrapper">
          <div className="layout-facility-data">
            <span className="authority-text">
              You are not authorized to view this page
            </span>
          </div>
        </div>
      </div>
    </React.Suspense>
  );
};
export default NoPermission;
