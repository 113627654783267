import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { APPLICATION_KEY, APPLICATION_USER } from "../../../constants/apiRoutes";

/**
 * get User Profile.
 * @returns {Promise} - A promise that resolves with get User Profile or rejects with an error.
 * @param props
 */
export const getUserProfile = async ({
  accessToken,
  moduleName,
  isServiceWorker,
  listController,
  user,
}) => {
  const response = await apiRequest({
    url: `${APPLICATION_USER}/TenantUser/${user?.userId}`,
    method: "GET",
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return {
    data: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Update User Profile
 * @returns {Promise} - A promise that resolves with the list of Tests or rejects with an error.
 * @param props
 */
export const updateUserProfile = async ({
  data,
  accessToken,
  moduleName,
  isServiceWorker,
  listController,
  user,
}) => {
  const response = await apiRequest({
    url: `${APPLICATION_USER}/${user?.userId}`,
    body: data,
    method: "PUT",
    moduleName,
    isServiceWorker,
    listController,
    accessToken,
  });
  return response;
};

/**
 * susbcribe feature.
 * @returns {Promise} - A promise that resolves with the list of users or rejects with an error.
 * @param props
 */
export const createAppKey = async () => {
  const { accessToken } = getTokensFromStorage();
  const data = {};
  const response = await apiRequest({
    url: `${APPLICATION_KEY}`,
    body: data,
    method: "POST",
    accessToken,
  });
  return response;
};

/**
 * User Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const apiRequest = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
