import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import {
  ORGANIZATION_PRODUCTS,
  ORGANIZATION_FEATURE,
  USER_FEATURES,
} from "../../../constants/apiRoutes";
/**
 * Lists products.
 * @returns {Promise} - A promise that resolves with the list of products or rejects with an error.
 * @param props
 */
export const listProducts = async ({ orgId, isSubscribed }) => {
  const { accessToken } = getTokensFromStorage();
  const response = await productsApiCall({
    url: `${ORGANIZATION_PRODUCTS.replace("{orgId}", orgId)}?OrgId=${orgId}&isSubscribed=${isSubscribed}`,
    method: "GET",
    accessToken,
  });
  return response.Payload;
};

/**
 * susbcribe feature.
 * @returns {Promise} - A promise that resolves with the list of users or rejects with an error.
 * @param props
 */
export const subscribeProducts = async ({ orgId, feature_id }) => {
  const { accessToken } = getTokensFromStorage();
  const data = [
    {
      feature_id: feature_id,
      organization_id: orgId,
      active: true,
    },
  ];
  const response = await productsApiCall({
    url: `${ORGANIZATION_FEATURE.replace("{orgId}", orgId)}`,
    body: data,
    method: "POST",
    accessToken,
  });
  return response;
};
/**
 * susbcribe All feature.
 * @returns {Promise} - A promise that resolves with the list of users or rejects with an error.
 * @param props
 */
export const subscribeAllFeatures = async ({ orgId, product_id }) => {
  const { accessToken } = getTokensFromStorage();
  const routeName = `${ORGANIZATION_PRODUCTS.replace("{orgId}", orgId)}`;
  const data = [
    {
      product_id: product_id,
      organization_id: orgId,
      active: true,
    },
  ];
  const response = await productsApiCall({
    url: `${routeName}`,
    body: data,
    method: "POST",
    accessToken,
  });
  return response;
};

/**
 * User Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const productsApiCall = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
