import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loader, ErrorFallback } from "smart-react";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { ListView } from "@progress/kendo-react-listview";
import "./OrganizationProducts.scss";
import {
  listProducts,
  subscribeProducts,
  subscribeAllFeatures,
} from "../Services/OrganizationProductService";
import { getOrganizationDetail } from "../../Organization/Services/OrganizationService";
import {
  hasUserRole,
  getDefaultTenant,
} from "../../../Utils/Auth/ValidatePermissions";
import {
  ExpansionPanel,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox } from "@progress/kendo-react-inputs";
import { useAuth } from "../../Core/Context/AuthContext";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { buildNotification, GenerateNotification } from "smart-react";
import { ListViewItemRender } from "../Components/ListView/ListView";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../../constants/eventDataTypes";
import { ROLES } from "../../../constants/applicationConstants";
/**
 * OrganizationProducts main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The OrganizationProducts component.
 */
const OrganizationProducts = () => {
  const [loader, setIsLoader] = React.useState(true);
  const [products, setProducts] = React.useState([]);
  const [expanded, setExpanded] = React.useState("Brazil");
  const [visibleDialog, setVisibleDialog] = React.useState(false);
  const [aggrement, setAggrement] = React.useState(false);
  const [isAllowed, setIsAllowed] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [selectedFeature, setSelectedFeature] = React.useState(null);
  const [productID, setProductID] = React.useState(null);
  const tooltip = React.useRef(null);
  const notificationMetaData = {
    title: "Succefully Subscribed",
    description: "You have access to fully functional feature for 90 days",
    style: "success",
  };
  const { getTokensFromStorage, user, tenantID } = useAuth();
  const [orgId, setOrgId] = React.useState("");

  /**
   * Used to Handle Subscribe Popup
   */
  const toggleDialog = () => {
    setSelectedFeature(null);
    setVisibleDialog(!visibleDialog);
  };

  /**
   * Handle Subscribe Form Submission
   */
  const submitDialog = async () => {
    if (aggrement) {
      setIsError(false);
      setIsLoader(true);
      if (productID === null) {
        const susbcribeProducts = await subscribeProducts({
          feature_id: selectedFeature,
          user,
          orgId,
        });
        if (susbcribeProducts?.IsSuccess) {
          const tempProducts = products?.map((product) => {
            product?.Listfeaturevm?.map((feature) => {
              if (feature.feature_id === selectedFeature) {
                feature.Issubscribed = true;
              }
            });
            return product;
          });
          setProducts(tempProducts);
          GenerateNotification(
            buildNotification(notificationMetaData),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
        } else {
          const notificationErrorMetaData = {
            title: "Subscription Error",
            description:
              "There was an issue with your subscription. Please try again later.",
            style: "error",
          };
          GenerateNotification(
            buildNotification(notificationErrorMetaData),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
        }
      }
      if (selectedFeature === null) {
        const susbcribeProducts = await subscribeAllFeatures({
          product_id: productID,
          orgId,
        });
        if (susbcribeProducts?.IsSuccess) {
          const tempProducts = products?.map((product) => {
            if (product.product_id === productID) {
              product?.Listfeaturevm?.map((feature) => {
                feature.Issubscribed = true;
              });
            }

            return product;
          });
          setProducts(tempProducts);
          GenerateNotification(
            buildNotification(notificationMetaData),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
        } else {
          const notificationErrorMetaData = {
            title: "Subscription Error",
            description:
              "There was an issue with your subscription. Please try again later.",
            style: "error",
          };

          GenerateNotification(
            buildNotification(notificationErrorMetaData),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
        }
      }

      toggleDialog();
      setIsLoader(false);
      setAggrement(false);
    } else {
      setIsError(true);
    }
  };

  /**
   * Used to check is feature enabled for product
   * @param {Object} product
   * @returns
   */
  const isFeaturesEnable = (product) => {
    let isEnabled = false;
    product?.Listfeaturevm.map((feature) => {
      if (feature?.IsEnabled) {
        isEnabled = true;
      }
    });
    return isEnabled;
  };

  /**
   * Set value of user agreement
   * @param {*} event
   */
  const handleAgreementChange = (event) => {
    setAggrement(event.value);
  };

  /**
   * Set Feature id and open dialog
   * @param {*} feature_id
   */
  const subscribeFeature = (feature_id) => {
    toggleDialog();
    setSelectedFeature(feature_id);
  };

  /**
   * Set product to susbcribe all features of this product
   * @param {*} product_id
   */
  const subscribeAll = (product_id) => {
    toggleDialog();
    setProductID(product_id);
  };

  /**
   * Used to get list of products
   */
  React.useEffect(() => {
    if (orgId) {
      (async () => {
        setIsLoader(true);
        const productsData = await listProducts({ orgId, isSubscribed: false });
        setProducts(productsData);
        setIsLoader(false);
      })();
    }
  }, [orgId]);

  React.useEffect(() => {
    const { accessToken } = getTokensFromStorage();
    (async () => {
      setIsLoader(true);
      const response = await getOrganizationDetail({
        accessToken,
        isServiceWorker: false,
        user,
      });
      setIsLoader(false);
      let responseData = response?.data?.filter((org) => org.name !== "world");
      if (responseData?.length > 0) {
        let tempData = responseData?.filter(
          (org) => org.tenant_id === tenantID
        );
        if (tempData?.length > 0) {
          setOrgId(tempData[0].organization_id);
        } else {
          let tempData = responseData?.filter(
            (org) => org.tenant_id === getDefaultTenant()?.TenantId
          );
          setOrgId(tempData[0].organization_id);
        }
      }
    })();
  }, [tenantID]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          {loader && <Loader />}
          {products?.length > 0 && (
            <div className="product-row k-pr-5 k-pl-5 k-pb-5 product-page">
              {products?.map((product, index) => (
                <ExpansionPanel
                  title={product.display_name}
                  subtitle={
                    <button
                      className={`subscribe-button`}
                      onClick={() => subscribeAll(product.product_id)}
                      disabled={
                        !isFeaturesEnable(product) ||
                        !hasUserRole(user, ROLES?.OrganizationAdmin)
                      }
                    >
                      Subscribe All
                    </button>
                  }
                  expanded={expanded === product.product_id}
                  tabIndex={0}
                  key={product.product_id}
                  onAction={(event) => {
                    setExpanded(event.expanded ? "" : product.product_id);
                  }}
                >
                  <Reveal>
                    {expanded === product.product_id && (
                      <ExpansionPanelContent>
                        <div className="product-col">
                          <Card
                            className="product-subscription-card "
                            key={index}
                          >
                            <CardBody className="product-subscription-body">
                              <ul className="subscription-features-list">
                                <ListView
                                  data={product?.Listfeaturevm}
                                  item={(props) => (
                                    <ListViewItemRender
                                      {...props}
                                      subscribeFeature={subscribeFeature}
                                      isCompanyAdmin={hasUserRole(
                                        user,
                                        ROLES?.OrganizationAdmin
                                      )}
                                    />
                                  )}
                                />
                              </ul>
                            </CardBody>
                          </Card>
                        </div>
                      </ExpansionPanelContent>
                    )}
                  </Reveal>
                </ExpansionPanel>
              ))}
              <Tooltip
                ref={tooltip}
                anchorElement="target"
                position="left"
                openDelay={300}
              />
            </div>
          )}
          {products?.length <= 0 && !loader && (
            <h6 className="k-py-5 k-text-center">
              No Organization Products Found
            </h6>
          )}
          {visibleDialog && (
            <Dialog
              title={"Please Confirm"}
              onClose={toggleDialog}
              width={500}
              height={300}
            >
              <div
                style={{
                  margin: "25px",
                  textAlign: "center",
                }}
              >
                {isAllowed ? (
                  <>
                    <p>
                      {" "}
                      The trial is available for BlueYonder employees and
                      BlueYonder customers. It is not available for
                      subcontractors that are working for BlueYonder or
                      BlueYonder customers.{" "}
                    </p>
                    <Checkbox
                      className={isError ? "checkbox-error " : ""}
                      validityStyles={true}
                      label={
                        " I acknolwdge that I meet the requirements of the trial"
                      }
                      onChange={handleAgreementChange}
                    />
                  </>
                ) : (
                  <p>
                    If you want to try out and you are not allowed to do so,
                    send email to sales@smart-is.com
                  </p>
                )}
              </div>
              <DialogActionsBar>
                <Button onClick={submitDialog} themeColor={"primary"}>
                  Submit
                </Button>
              </DialogActionsBar>
            </Dialog>
          )}
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default OrganizationProducts;
